/* layout css */
@font-face {
    font-family: 'Century Gothic';
    src:
         url('./century-gothic/CenturyGothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.layout_css {
    min-height: 100vh;
    overflow: hidden;
    height: 100vh;
}

.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
    color: #FFFFFF;
}

.trigger:hover {
    color: #1890ff;
}

.logo {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    background: #FFFFFF;
}

.logo.shortlogo img {
    width: 80px;
    align-self: start;
    height: 63px;
}

.logo.longlogo {
    border-right: 0.2px solid #000000;
    background: #071251;
}

.site-layout .site-layout-background {
    background: #F3F3F9;
}

.siderLowerStickeyText {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    text-align: center;
}

.layout_sidebar {
    background: #071251;
}

.layout_sidebar .ant-layout-sider-trigger {
    height: auto !important;
    color: #fff;
    line-height: 16px !important;
    cursor: unset;
    padding: 5px;
    background: #071251;
    color: #212529;
    font-size: 12px;
    word-break: break-all;
}

.layout_sidebar .siderbar_footer a {
    font-weight: 600;
    color: #212529;
}

.layout_sidebar .sidebar_menus {
    height: calc(100vh - 124px);
    overflow-y: auto;
}

.layout_sidebar .ant-menu-light .ant-menu-item-active,
.layout_sidebar .ant-menu-light .ant-menu-submenu-active,
.layout_sidebar .ant-menu-light .ant-menu-submenu-title:hover,
.layout_sidebar .ant-menu-light .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
    color: #1559A2;
}

.layout_sidebar .ant-menu-light .ant-menu-item:hover {
    background-color: #1559A2;
    color: #FFFFFF;
}

.layout_sidebar .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #1559A2;
    color: #FFFFFF;
}

.layout_sidebar .ant-menu-submenu-selected,
.layout_sidebar .ant-menu-submenu-selected>.ant-menu-submenu-arrow {
    color: #1559A2;
}

.layout_sidebar .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.layout_sidebar .ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
    color: #1559A2;
}


.layout_sidebar .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.layout_sidebar .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
    color: #1559A2;
}

.layout_sidebar .ant-menu-vertical .ant-menu-item::after,
.layout_sidebar .ant-menu-vertical-left .ant-menu-item::after,
.layout_sidebar .ant-menu-vertical-right .ant-menu-item::after,
.layout_sidebar .ant-menu-inline .ant-menu-item::after {
    border-right: 0px;
}


/* custom_table_css  */
.antd_table {
    border: 1px solid #dee2e6;
    background-color: #FFFFFF;
    color: #212529;
    font-weight: 500;
}

.antd_table thead>tr>th {
    padding: 0.75rem !important;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
    font-weight: 700;
}

.antd_table tbody>tr>td {
    padding: 0.75rem;
    border-bottom: 1px solid #dee2e6 !important;
    border-right: 1px solid #dee2e6 !important;
}

.antd_table .ant-table {
    background-color: #FFFFFF;
    color: #212529;
}

.antd_table tbody tr:nth-child(odd) {
    background: #FFFFFF;
}

.antd_table tbody tr:nth-child(even) {
    background: #F8F8F8;
}

.antd_table.no_data_found_hide .ant-table-empty .ant-table-tbody>tr.ant-table-placeholder {
    display: none;
}

.antd_table .ant-table-pagination.ant-pagination {
    margin: 10px 5px;
}

/* css antd custom label  */
.ant-form-item-label label {
    font-weight: 500;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: inline-block;
    margin-left: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}
.logout-btn{
    background-color: #1559a2;
        padding: 8px 10px;
        color: #fff;
        font-size: 15px;
        cursor: pointer;
        border-radius: 5px;
}
.logout-btn svg{
    vertical-align: initial;
    padding-left: 5px;
    width: 20px;
}
.sidebar_menus svg{
    width: 20px;
}